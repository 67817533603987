import {
  Box,
  Flex,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, { useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { getHeaderLogoByPartner } from '.';
import { formatToCurrency } from '../../../../utils/util';
import { getPryBtnColorByPartner } from '../../partner-config';

type Props = {
  plan: {
    id: string;
    description: string;
    price: string;
    name: string;
    planType: string;
    plan_info: string[];
    cover_value: string;
    plan_tnc: string;
  };
  duration: { id: number; type: string }[];
  activeTab: number;
};

const BapCard = ({ plan, duration, activeTab }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [hasMore] = useState(
    (plan.plan_info && plan.plan_info?.length > 1) || false
  );

  return (
    <Box
      id="w-node-_8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d-df636ad8"
      data-w-id="8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d"
      className="product-feature-item"
    >
      <Box className="product-feature-widget-title product-plans">
        <Box className="widget-square-box">
          <Box className="widget ai-notification-widget">
            {getHeaderLogoByPartner()}
            <Box className="heading-four">
              {plan.name.replace(/\bdevice\b/gi, '')}
            </Box>
            <Box className="heading-four">
              {formatToCurrency(plan.price)}/
              {duration[activeTab].type.toLowerCase()}
            </Box>
            <Flex gap={2}>
              <Link
                href={{
                  pathname: `/device-care/plan`,
                  query: {
                    activeTab,
                    id: plan.id,
                    price: plan.price,
                    tnc: plan.plan_tnc,
                  },
                }}
                className="button plan-button bg-gray-dark w-inline-block"
                style={{
                  backgroundColor: getPryBtnColorByPartner(),
                }}
              >
                <div className="button-text text-primary-1b">Buy Plan</div>
                <div className="button-effect bg-white"></div>
              </Link>

              {/* <Link
            href={`/bap/${plan.id}`}
            shallow
            className="button plan-button bg-primary-2b w-inline-block"
            style={{
              backgroundColor: 'transparent',
              border: `2px solid ${getPryBtnColorByPartner()}`,
              color: getPryBtnColorByPartner(),
            }}
          >
            <Text
              className="button-text"
              fontSize="md"
            >
              View Details
            </Text>
            <Box className="button-effect bg-primary-3c"></Box>
          </Link> */}
            </Flex>
            {/* <Box>
              Free device repairs worth {formatToCurrency(plan.cover_value)}{' '}
              <br />
            </Box> */}
            {plan.plan_info.length && (
              <Stack
                align={['flex-start']}
                w="full"
                justify="space-between"
                fontSize="14px"
              >
                <List spacing={3} textAlign="left">
                  <ListItem display="flex" alignItems="center">
                    <ListIcon as={MdCheckCircle} color="blue.500" />
                    {plan.plan_info?.[0]}
                  </ListItem>

                  {showMore &&
                    plan.plan_info
                      ?.filter((desc, idx) => idx !== 0)
                      .map((desc) => (
                        <ListItem key={desc} display="flex" alignItems="center">
                          <ListIcon as={MdCheckCircle} color="blue.500" />
                          {desc}
                        </ListItem>
                      ))}
                </List>
                {hasMore && (
                  <Text
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMore((prev) => !prev);
                    }}
                    color="blue.500"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    fontSize="xs"
                    cursor="pointer"
                  >
                    {showMore ? 'See less' : 'More benefits'}
                  </Text>
                )}
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BapCard;
