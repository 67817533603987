/* eslint-disable @next/next/no-img-element */
import { useQuery } from '@apollo/client';
import { Box, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import Link from 'next/link';
import React, { RefObject, useState } from 'react';

import { GET_INSURANCE_PLANS } from '../../../../api/query/device-care';
import { FullPageSpinner } from '../../../primitives';
import { getFaintBgByPartner } from '../../partner-config';
import BapCard from './bap-card';
import Carousel from './carousel';

const duration = [
  {
    id: 0,
    type: 'MONTHLY',
  },
  {
    id: 1,
    type: 'YEARLY',
  },
  // {
  //   id: 2,
  //   type: 'WEEKLY',
  // },
  // {
  //   id: 3,
  //   type: 'DAILY',
  // },
];

const tabListStyles = {
  padding: '4px',
  marginBottom: '20px',
  borderBottom: 'none !important',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    display: 'none',
  },
};

const tabStyles = {
  borderBottom: 'none !important',
  background: '#dddddd',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  padding: '10px',
  paddingInline: '30px',
  active: {
    background: '#C8C8C8',
  },
};

export const getTextByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'My MTNPicknFix';
    case 'AIRNG':
      return 'Airtel PicknFix';
    default:
      return 'PicknFix';
  }
};

const getHeaderByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'My MTN';
    case 'AIRNG':
      return 'airtel';
    default:
      return 'PNF';
  }
};

const getColorByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return '#ffcb05';
    case 'AIRNG':
      return '#ffdfdf';
    default:
      return '#0F61D6';
  }
};

export const getHeaderLogoByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return (
        <Box
          className="badge ai-notification-badge"
          style={{ backgroundColor: '#ffcb05' }}
        >
          <img
            width="50"
            height="50"
            alt=""
            src={`images/mtn-pp-badge.svg`}
            sizes="33px"
            // srcSet="images/MTN_2022_Logo_Black_RGB-sml-p-500.webp 500w, images/MTN_2022_Logo_Black_RGB-sml-p-800.webp 800w, images/MTN_2022_Logo_Black_RGB-sml-p-1080.webp 1080w, images/MTN_2022_Logo_Black_RGB-sml.webp 1200w"
            className="image"
          />
          <Box className="text-bold">
            <strong>MTN</strong>
          </Box>
        </Box>
      );
    case 'AIRNG':
      return (
        <Box
          className="badge ai-notification-badge"
          style={{ backgroundColor: '#E20010' }}
        >
          <img
            width="50"
            height="50"
            alt=""
            src={`images/air-pp-badge.svg`}
            sizes="33px"
            className="image"
          />
          <Box className="text-bold" color="white">
            <strong>airtel</strong>
          </Box>
        </Box>
      );
    default:
      return (
        <Box className="badge ai-notification-badge">
          <img
            alt=""
            src={`images/pnf-pp-badge.svg`}
            sizes="33px"
            className="image"
          />
        </Box>
      );
  }
};

const ppViews = process.env.NEXT_PUBLIC_PP_VIEWS;

const ProtectionPlan = ({
  sectionRef,
}: {
  sectionRef: RefObject<HTMLDivElement>;
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const { loading: loadingPlans, data: plans } = useQuery<{
    insurance_plan: {
      id: string;
      description: string;
      price: string;
      name: string;
      planType: string;
      plan_info: string[];
      cover_value: string;
      plan_tnc: string;
    }[];
  }>(GET_INSURANCE_PLANS, {
    variables: { partnerCode: process.env.NEXT_PUBLIC_PARTNER_CODE },
  });

  return (
    <Box pt={5} ref={sectionRef} id="protection-plan">
      {/* <Navbar /> */}
      {loadingPlans && <FullPageSpinner />}

      {ppViews?.includes('two') && (
        <Box
          as="section"
          className="section protection-plan"
          bg={getFaintBgByPartner()}
        >
          <Box className="container---main" maxW="1400px" mx="auto">
            <Box className="product-features-grid-section">
              <Box className="heading-one text-center">
                Protection Cover for all your devices
              </Box>
              <Tabs
                onChange={(index) => {
                  setActiveTab(index);
                }}
              >
                <TabList className="tabs-menu w-tab-menu" css={tabListStyles}>
                  <Box
                    w="full"
                    display="flex"
                    justifyContent="flex-end"
                    data-current="Yearly"
                    data-easing="ease"
                    data-duration-in="300"
                    data-duration-out="100"
                    className="w-tabs"
                  >
                    {duration.map((dur) => (
                      <Tab
                        key={dur.id}
                        className="w-inline-block w-tab-link"
                        _selected={tabStyles.active}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        css={tabStyles}
                      >
                        {dur.type.toLowerCase()}
                      </Tab>
                    ))}
                  </Box>
                </TabList>
                <TabPanels>
                  <Box className="w-tab-content">
                    <Box className="product-grid-thirds">
                      {plans?.insurance_plan
                        ?.filter(
                          (plan) => plan.planType === duration[activeTab].type
                        )
                        ?.map((plan) => (
                          <BapCard
                            plan={plan}
                            key={plan.id}
                            duration={duration}
                            activeTab={activeTab}
                          />
                        ))}
                    </Box>
                  </Box>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Box>
      )}

      {/* {ppViews?.includes('one') && ( */}
      <Box as="section" className="section" id="protection-plan">
        <Box className="container---main">
          <Box
            data-w-id="cfeb3857-bca4-2696-44bc-e64f0eedc347"
            className="grid-halves"
          >
            <Box
              id="w-node-cfeb3857-bca4-2696-44bc-e64f0eedc34a-df636a8f"
              className="container---s"
            >
              <Box className="multipurpose-text-section">
                <Box className="heading-one">Protect the devices you love</Box>
                <Box className="horizontal-icon-features">
                  <Box className="horizontal-icon-feature">
                    <img
                      src="images/interface-icon-check.svg"
                      alt="Checkmark icon"
                      className="horizontal-icon-feature-image"
                    />
                    <Box className="text-block-18">
                      <strong className="bold-text-5">
                        Affordable Protection Plans
                      </strong>
                    </Box>
                  </Box>
                  <Box className="horizontal-icon-feature">
                    <img
                      src="images/interface-icon-check.svg"
                      alt="Checkmark icon"
                      className="horizontal-icon-feature-image"
                    />
                    <Box className="text-block-18">
                      <strong className="bold-text-5">
                        Covers brand new and used devices
                      </strong>
                    </Box>
                  </Box>
                  <Box className="horizontal-icon-feature">
                    <img
                      src="images/interface-icon-check.svg"
                      alt="Checkmark icon"
                      className="horizontal-icon-feature-image"
                    />
                    <Box className="text-block-20">
                      <strong className="bold-text-6">
                        Fast repairs with Manufacturer Approved Service Centers
                      </strong>
                    </Box>
                  </Box>
                  <Box className="horizontal-icon-feature">
                    <img
                      src="images/interface-icon-check.svg"
                      alt="Checkmark icon"
                      className="horizontal-icon-feature-image"
                    />
                    <Box className="text-block-19">
                      <strong>Hassle-free Claim Process</strong>
                    </Box>
                  </Box>
                </Box>
                <Link
                  href="/device-care/plan"
                  className="button bg-gray-dark w-inline-block"
                >
                  <Box className="button-text text-primary-3b">
                    Protect your device
                  </Box>
                  <Box className="button-effect bg-white"></Box>
                </Link>
              </Box>
            </Box>
            <Box className="widget composite">
              <img
                src="images/photo-square-03_compressed.webp"
                sizes="(max-width: 479px) 89vw, 428px"
                width="758"
                height="1004"
                alt=""
                srcSet="images/photo-square-03_compressed-p-500.webp 500w, images/photo-square-03_compressed-p-800.webp 800w, images/photo-square-03_compressed.webp 1000w"
              />
              <Box className="widget-notification">
                <Box className="widget-notification-title">
                  <Box className="widget-icon-circle">
                    <img
                      src="images/decorative-icon-junk-food-dark.svg"
                      alt=""
                      width="48"
                      height="48"
                      className="widget-icon-circle-icon"
                    />
                  </Box>
                  <Box>
                    <Box className="small-text text-bold">Drinks at Yonder</Box>
                    <Box className="small-text muted">$37.50</Box>
                  </Box>
                </Box>
                <Box className="small-text muted">11:30am</Box>
              </Box>
              <Box className="widget-notification">
                <Box className="widget-notification-title">
                  <Box className="widget-icon-circle">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                      className="widget-icon-circle-icon"
                    />
                  </Box>
                  <Box>
                    <Box className="small-text text-bold">
                      Bronze Monthly Plan b
                    </Box>
                    <Box className="small-text muted">N850</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* )} */}

      {ppViews?.includes('three') && (
        <Box as="section" className="section">
          <Box className="container---main">
            <Box className="feature-grid-section">
              <Box className="heading-two feature-grid-heading">
                The best device and repair protection service in the market.
              </Box>
              <Box className="small-features-grid-thirds">
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Fast and reliable</Box>
                  </Box>
                  <Box className="text-block-2">
                    Fastest repair Turn Around Time by manufacturer approved
                    after sales support services centres
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Quality assured</Box>
                  </Box>
                  <Box className="text-block-3">
                    All repairs undertaken according to high standards set by
                    your device’s manufacturer. Quality repairs delivered every
                    time
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Unrivalled delivery</Box>
                  </Box>
                  <Box className="text-block-4">
                    Access 24/7 repairs updates through multiple channels so you
                    know exactly when your device is coming back to you
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Manufacturer Approved SC</Box>
                  </Box>
                  <Box className="text-block-7">
                    Every single service center on the PicknFix network has been
                    approved and vetted by the manufacturer of your device to
                    offer in and out of warranty services.
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Varieties of devices</Box>
                  </Box>
                  <Box className="text-block-6">
                    Fix Desktop, Laptop Computers, Routers, Tablets, Mobile
                    Phones, TVs, Mifis, Printers, Monitors, Gaming Consoles,
                    Microwaves, Cameras, Scanners and many more
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Make Claims</Box>
                  </Box>
                  <Box className="text-block-5">
                    Make claims in simple steps from the PicknFix mobile app.
                    Easy and effortless.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {ppViews?.includes('four') && <Carousel />}
    </Box>
  );
};

export default ProtectionPlan;
